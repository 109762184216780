import React, { useState } from 'react'
import { Link } from 'react-scroll'
import { Link as Link2 } from 'react-router-dom'
import { StarIcon } from '@heroicons/react/solid'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import SwiperCore, {
    Pagination
  } from 'swiper';
import FranchisePop from './FranchisePop';
import { useLocation } from 'react-router-dom';
  
  // install Swiper modules
  SwiperCore.use([Pagination]);

function Testimonials() {
    const [franchisePop, setfranchisePop] = useState(false)
    const location = useLocation()
    return (
        <div className="w-full font-openSans bg-white">
            <div className="w-11/12 py-20 mx-auto my-0 flex flex-col space-y-5 md:space-y-0 md:flex-row max-w-screen-2xl">
                {/* left */}
                <div className="flex-1 overflow-hidden">
                    <div className="text-3xl font-semibold">Testimonials</div>
                    <div className="text-lg">Hear it from those who love avocados as well as Avoke</div>
                   <div className="hidden sm:block pt-5">
                   <Swiper
                    slidesPerView={2}
                    spaceBetween={20}
                    onSlideChange={() => console.log("slide change")}
                    onSwiper={swiper => console.log(swiper)}
                    pagination={true}
                    >
                        <SwiperSlide className="bg-white border p-5 mb-10 rounded-md">
                            <div className="flex items-center space-x-3 pb-5">
                                <img src="images/zomato.svg" alt="" className="h-12 w-12 rounded-full" />
                                <div>
                                    <div className="text-lg font-semibold">Sonu Raj</div>
                                    <div className="flex items-center">
                                        <StarIcon className="text-yellow h-4" />
                                        <StarIcon className="text-yellow h-4" />
                                        <StarIcon className="text-yellow h-4" />
                                        <StarIcon className="text-yellow h-4" />
                                        <StarIcon className="text-yellow h-4" />
                                    </div>
                                </div>
                            </div>
                            <div>I’m not a huge fan of avocado but I must say I was pleasantly surprised. Food and service was excellent</div>
                        </SwiperSlide>
                        <SwiperSlide className="bg-white border p-5 mb-10 rounded-md">
                            <div className="flex items-center space-x-3 pb-5">
                                <img src="/images/google.svg" alt="" className="h-12 w-12 rounded-full" />
                                <div>
                                    <div className="text-lg font-semibold">Smarika Verma</div>
                                    <div className="flex items-center">
                                        <StarIcon className="text-yellow h-4" />
                                        <StarIcon className="text-yellow h-4" />
                                        <StarIcon className="text-yellow h-4" />
                                        <StarIcon className="text-yellow h-4" />
                                        <StarIcon className="text-yellow h-4" />
                                    </div>
                                </div>
                            </div>
                            <div>The place you can enjoy diet food and it's really nice prepration and presentation. Must try once</div>
                        </SwiperSlide>
                        <SwiperSlide className="bg-white border p-5 mb-10 rounded-md">
                            <div className="flex items-center space-x-3 pb-5">
                                <img src="/images/zomato.svg" alt="" className="h-12 w-12 rounded-full" />
                                <div>
                                    <div className="text-lg font-semibold">Imthiyaz shaik</div>
                                    <div className="flex items-center">
                                        <StarIcon className="text-yellow h-4" />
                                        <StarIcon className="text-yellow h-4" />
                                        <StarIcon className="text-yellow h-4" />
                                        <StarIcon className="text-yellow h-4" />
                                        <StarIcon className="text-yellow h-4" />
                                    </div>
                                </div>
                            </div>
                            <div>Great food and very good hygiene. Really love the concept. Intent to try out all the items on the menu.</div>
                        </SwiperSlide>
                    </Swiper>
                    
                   </div>
                   {/* mobile */}
                   <div className="sm:hidden">
                   <Swiper
                    slidesPerView={1}
                    spaceBetween={20}
                    onSlideChange={() => console.log("slide change")}
                    onSwiper={swiper => console.log(swiper)}
                    pagination={true}
                    >
                        <SwiperSlide className="bg-white border p-5 mb-10 rounded-md mt-5">
                            <div className="flex items-center space-x-3 pb-5">
                                <img src="https://avoke.in/images/zomato.svg" alt="" className="h-12 w-12 rounded-full" />
                                <div>
                                    <div className="text-lg font-semibold">Sonu Raj</div>
                                    <div className="flex items-center">
                                        <StarIcon className="text-yellow h-4" />
                                        <StarIcon className="text-yellow h-4" />
                                        <StarIcon className="text-yellow h-4" />
                                        <StarIcon className="text-yellow h-4" />
                                        <StarIcon className="text-yellow h-4" />
                                    </div>
                                </div>
                            </div>
                            <div>I’m not a huge fan of avocado but I must say I was pleasantly surprised. Food and service was excellent</div>
                        </SwiperSlide>
                        <SwiperSlide className="bg-white border p-5 mb-10 rounded-md mt-5">
                            <div className="flex items-center space-x-3 pb-5">
                                <img src="https://avoke.in/images/google.svg" alt="" className="h-12 w-12 rounded-full" />
                                <div>
                                    <div className="text-lg font-semibold">Smarika Verma</div>
                                    <div className="flex items-center">
                                        <StarIcon className="text-yellow h-4" />
                                        <StarIcon className="text-yellow h-4" />
                                        <StarIcon className="text-yellow h-4" />
                                        <StarIcon className="text-yellow h-4" />
                                        <StarIcon className="text-yellow h-4" />
                                    </div>
                                </div>
                            </div>
                            <div>The place you can enjoy diet food and it's really nice prepration and presentation. Must try once</div>
                        </SwiperSlide>
                        <SwiperSlide className="bg-white border p-5 mb-10 rounded-md mt-5">
                            <div className="flex items-center space-x-3 pb-5">
                                <img src="https://avoke.in/images/zomato.svg" alt="" className="h-12 w-12 rounded-full" />
                                <div>
                                    <div className="text-lg font-semibold">Imthiyaz Shaik</div>
                                    <div className="flex items-center">
                                        <StarIcon className="text-yellow h-4" />
                                        <StarIcon className="text-yellow h-4" />
                                        <StarIcon className="text-yellow h-4" />
                                        <StarIcon className="text-yellow h-4" />
                                        <StarIcon className="text-yellow h-4" />
                                    </div>
                                </div>
                            </div>
                            <div>Great food and very good hygiene. Really love the concept. Intent to try out all the items on the menu.</div>
                        </SwiperSlide>
                    </Swiper>
                   </div>
                </div>
                {/* right */}
                <div className="flex-1 flex items-center justify-center">
                    <div className="border-2 border-gray-400 rounded-md md:w-4/6 xl:3/6">
                        <div className="bg-white rounded-md p-10 transform -rotate-3 border flex flex-col items-center justify-center">
                            <div className="transform rotate-3">
                                <div className="flex justify-center">
                                    <img src="https://global-uploads.webflow.com/60b6000ac2944e10375ce615/60f679fbf98ed917e2216d13_Frame-2.svg" alt="" className="h-10" />
                                </div>
                                <div className="text-xl font-medium text-center">Join the Avoke Fam</div>
                                <div className="text-base text-gray-700 text-center">Sign up so that you won't miss out the best business oppurtunity.</div>
                                {/* button */}
                                <div className="pt-8 flex justify-center space-x-3">
                                    {location.pathname === "/" ? <Link2 to="/franchise" className="bg-sage cursor-pointer hover:scale-105 text-sm text-white p-2 rounded-md px-4">Franchise</Link2> : <Link to="touch" spy={true} smooth={true} className="bg-sage cursor-pointer hover:scale-105 text-sm text-white p-2 rounded-md px-4">Sign Up</Link>}
                                    <button className="p-2 px-4 text-sm" onClick={() => setfranchisePop(true)}>Contact us</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {franchisePop && <FranchisePop handleClose={() => setfranchisePop(false)} />}
        </div>
    )
}

export default Testimonials
