import React from 'react';
import { Switch, Route } from 'react-router-dom'
import ScrollToTop from './components/ScrollToTop';
import Franchise from './Pages/Franchise';
import Home from './Pages/Home';
import Survey from './Pages/Survey';

function App() {
  return (
    <div className="text-textDark">
       <div>
       <ScrollToTop />
         <Switch>
           <Route path="/" exact>
             <Home />
           </Route>
           <Route path="/franchise">
             <Franchise />
           </Route>
           <Route path="/survey">
             <Survey />
           </Route>
         </Switch>
       </div>
    </div>
  );
}

export default App;
