import React, { useState } from 'react'
import { AiFillFacebook, AiFillInstagram, AiFillYoutube } from 'react-icons/ai'
import { Link } from 'react-router-dom'
import FranchisePop from './FranchisePop'

function Footer() {
    const [franchisePop, setfranchisePop] = useState(false)
    return (
        <div className="w-full font-openSans bg-green-800 text-white">
            {franchisePop && <FranchisePop handleClose={() => setfranchisePop(false)} />}
            <div className="w-11/12 mx-auto my-0 py-20 grid gap-5 md:grid-cols-3 max-w-screen-2xl">
                <div>
                   <img src="/images/logo.png" alt="" className="h-8 sm:h-12 sm:py-2 mb-3" />
                   <div className="w-5/6">1083, 12th main road, 5th Cross Rd, Indiranagar, Bengaluru, Karnataka 560038</div>
                   <div className="flex items-center space-x-1 pt-5">
                       <a href="https://www.facebook.com/theavacadocafe"><AiFillFacebook size={22} /></a>
                       <a href="https://www.instagram.com/avocadocafeblr/?hl=en"><AiFillInstagram size={22} /></a>
                       <a href="https://www.youtube.com/channel/UC54vZ8r0oKjdysSpul-iJ0w/about"><AiFillYoutube size={22} /></a>
                   </div>
                </div>
                <div className="">
                    <div className="text-2xl font-bold pb-2">Navigate</div>
                    <div className="flex flex-col space-y-2">
                        <Link to="/" className="cursor-pointer hover:underline">Home</Link>
                        <Link to="/franchise" className="cursor-pointer hover:underline">Franchise</Link>
                        <Link to='/survey' className="cursor-pointer hover:underline">Survey</Link>
                    </div>
                </div>
                <div>
                    <div className="text-2xl font-bold pb-2">Get in touch</div>
                    <div>Have a question or comment? Send us a message using the button below.</div>
                    <div className="pt-5">
                        <button className="bg-sage text-white p-3 px-6 rounded-md" onClick={() => setfranchisePop(true)}>Contact us</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer
