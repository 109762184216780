import React from 'react'
import { CheckIcon } from '@heroicons/react/outline'

function SurveyPop({handleClose}) {
    return (
        <div className="w-full h-screen fixed top-0 left-0 bg-black bg-opacity-25 flex justify-center items-center">
            <div className="bg-white shadow-lg rounded-md p-10 flex flex-col items-center space-y-8">
                <CheckIcon className="h-12 p-2 rounded-full bg-sage text-white" />
                <div className="text-2xl font-semibold">Claim your price.</div>
                <div className="text-center text-gray-500">Kudos for completing the survey!<br /> Claim your Avolicious prize now and Avo good time.</div>
                <button className="w-full text-center p-3 rounded-md bg-sage text-white" onClick={handleClose}>Claim</button>
            </div>
        </div>
    )
}

export default SurveyPop
