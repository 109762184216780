import React, { useState } from 'react'
import { XIcon, RefreshIcon } from '@heroicons/react/outline'
import { useForm } from 'react-hook-form'
import axios from '../axios'

function FranchisePop({handleClose}) {
    const { register, handleSubmit, formState: { errors }, watch } = useForm({mode: "all"})

    const [loading, setloading] = useState(false)

    const message = watch("message")

    const onSubmit = async (data) => {
        setloading(true)
        try{
            const response = await axios.post("/query", data)
            if(response.status === 201){
                setloading(false)
                window.open(`https://wa.me/+918209202107?text=${message}`, '_blank')
            }
        }catch(e){
            console.log(e)
        }
    }

    return (
        <div className="w-full h-screen fixed top-0 z-50 bottom-0 flex items-center justify-center bg-black bg-opacity-25">
            <XIcon className="text-white h-8 absolute right-5 top-5 cursor-pointer" onClick={handleClose} />
            <div className="bg-white rounded-md overflow-hidden w-max flex flex-col md:flex-row">
                {/* left */}
                <div className="bg-sage">
                    <img src="/images/pop.png" alt="" className="w-full h-36 md:h-full md:w-64 object-cover" />
                </div>
                {/* right */}
                <div className="p-5">
                    <div className="text-2xl font-semibold">Talk to us on Whatsapp</div>
                    <div className="text-base md:w-3/4">Let us know your query. We are available throughout the day.</div>
                    <form onSubmit={handleSubmit(onSubmit)} className="pt-5 flex flex-col space-y-5">
                        <div className="flex flex-col relative">
                            <label htmlFor="" className="text-sm text-gray-600">Your Name</label>
                            <input type="text" className=" outline-none border-b p-2" placeholder="Enter your name" {...register("name", {
                                required: {
                                    value: true,
                                    message: "Please enter name"
                                }
                            })} />
                            {errors.name && <p className="text-xs text-red absolute -bottom-5 ">{errors.name.message}</p>}
                        </div>
                        <div className="flex flex-col relative">
                            <label htmlFor="" className="text-sm text-gray-600">Your Whatsapp Number</label>
                            <input type="text" className=" outline-none border-b p-2" placeholder="Enter your whatsapp number" {...register('phone', {
                                required: {
                                    value: true,
                                    message: 'Enter your whatsapp number'
                                },
                                pattern: {
                                    value: /^[0-9]{10}$/,
                                    message: 'Enter valid whatsapp number'
                                }
                            })} />
                            {errors.phone && <p className="text-xs text-red absolute -bottom-5 ">{errors.phone.message}</p>}
                        </div>
                        <div className="flex flex-col relative">
                            <label htmlFor="" className="text-sm text-gray-600">Tell us your query</label>
                            <textarea type="text" className=" outline-none border-b p-2" placeholder="Hey i am looking to collaborate" {...register('message', {
                                required: {
                                    value: true,
                                    message: 'Enter your query.'
                                }
                            })} />
                            {errors.message && <p className="text-xs text-red absolute -bottom-5 ">{errors.message.message}</p>}
                        </div>
                        <div className="flex justify-end">
                            <button type="submit" className="p-3 px-6 rounded-md text-white bg-sage flex items-center">Whatsapp {loading && <RefreshIcon className="h-6 ml-2 animate-spin delay-200" />}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default FranchisePop
