import { ChatAltIcon, DocumentIcon, HomeIcon } from '@heroicons/react/outline'
import React from 'react'
import { Link } from 'react-router-dom'

function DrawerHomeNav() {
    return (
        <div className="flex flex-col space-y-2 pt-10">
            <Link to="/" className="p-2 px-4 flex items-center w-40"><HomeIcon className="h-6 mr-2" /><div className="font-medium flex-grow">Home</div></Link>
            <Link to="/survey" className="p-2 px-4 flex items-center w-40"><DocumentIcon className="h-6 mr-2" /><div className="font-medium flex-grow">Survey</div></Link>
            <Link to="/franchise"  className="p-2 px-4 flex items-center w-40"><ChatAltIcon className="h-6 mr-2" /><div className="font-medium flex-grow">Franchise</div></Link>
        </div>
    )
}

export default DrawerHomeNav
