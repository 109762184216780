import React from 'react'

function About() {
    return (
        <div id="about" className="w-full font-openSans bg-greenIn max-w-screen-2xl mx-auto my-0 relative">
            <img src="/images/black-pattern.svg" alt="" className="absolute top-0 left-0 h-full md:h-screen w-full object-cover" />
            <div className="mx-auto my-0 flex flex-col md:flex-row h-full md:h-screen py-20 sm:py-0 w-11/12">
                {/* left */}
                <div className="py-10 flex-1 flex justify-end items-center">
                    <div className="sm:pr-10 text-white">
                        <div className="text-lg">About us</div>
                        <div className="text-3xl sm:text-5xl font-semibold pb-5">Say Hello to Avoke</div>
                        <video loop autoPlay muted className="sm:hidden h-full rounded-md">
                            <source src="/images/video.mp4" type="video/mp4" className="rounded-md" />
                        </video>
                        <div className="text-base pb-3">India’s first and freshest Hass avocado brand. Welcome to the world of crispy avocado toasts, creamy avocado Burgers , and wholesome bowls of guacamole. With Avoke, you can enjoy these treats at home or take a break in our cafe with friends. Active people who want more greens in their diet will love that Avoke is available for door-step delivery too! Avoke is Indian first avocado brand which brings you avocado experience in through cafe, retail and your doorstep. Knowing everything about Hass avocados has made us India’s freshest and tastiest natural ingredient brand so far. Served chilled or soft; smooth as silk but never mushy – any way it is served up we have cracked the difficult question of how best to serve this through our cafe .</div>
                        <div className="text-lg">Avoke always provide the best of quality for the best of people.</div>
                    </div>
                </div>
                {/* right */}
                <div className="hidden flex-1 sm:flex items-center">
                    <video loop autoPlay muted className="h-full rounded-md">
                        <source src="/images/video.mp4" type="video/mp4" className="rounded-md" />
                    </video>
                </div>
            </div>
        </div>
    )
}

export default About
