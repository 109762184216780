import React from 'react'

function Award() {
    return (
        <div className="w-full font-openSans bg-gray-50">
            <div className="w-11/12 py-20 mx-auto my-0">
                {/* left */}
                <div className="flex flex-col items-center">
                    <img src="/images/check-mark.png" className="h-10" alt="" />
                    <div className="text-lg text-sage">Recognition</div>
                    <div className="text-3xl md:text-5xl font-bold py-2 text-center">Emerging concept of the year</div>
                    <img src="/images/logo.png" className="h-10" alt="" />
                </div>
                {/* right */}
                <div className="pt-8 flex justify-center">
                    <img src="/images/award.jpeg" alt="" className="h-60 object-cover md:h-96 rounded-md shadow-lg" />
                </div>
            </div>
        </div>
    )
}

export default Award
