import React from 'react'
import ReactTypingEffect from 'react-typing-effect';

function BusinessModal() {
    return (
        <div className="w-full font-openSans bg-white">
            <div className="w-11/12 py-20 mx-auto my-0 max-w-screen-2xl flex flex-col items-center">
                 <div className="flex flex-col items-center">
                     <img src="https://global-uploads.webflow.com/60b6000ac2944e10375ce615/60f679fbf98ed917e2216d13_Frame-2.svg" alt="" className="h-24 object-contain" />
                     <div className="text-3xl font-semibold text-center">Be the first to invest in<br className="sm:hidden" /> <ReactTypingEffect className="text-sage" text={["Green Gold", "Health"]}/>.</div>
                     <div className="text-lg text-center">Choose your mode and be the first to join the Avoke Fam</div>
                 </div>
                 <div className="pt-8 border-b pb-5 md:pb-0 sm:border-0 grid lg:grid-cols-2 gap-x-5 w-full md:w-4/6">
                     <div className="py-5 sm:p-5 bg-white flex flex-col justify-center">
                         <div className="text-3xl font-semibold">Kiosk</div>
                         <div className="text-sage text-lg pt-2 pb-4">Investment 12 Lakh onwards</div>
                         <div className="flex flex-col space-y-2">
                             <div className="flex items-center justify-between bg-back px-1">
                                 <div className="font-semibold">Investment</div>
                                 <div>12 - 17 Lakh</div>
                             </div>
                             <div className="flex items-center justify-between px-1">
                                 <div className="font-semibold">Area required</div>
                                 <div>100-300 sq.ft</div>
                             </div>
                             <div className="flex items-center justify-between bg-back px-1">
                                 <div className="font-semibold">Menus</div>
                                 <div>70%</div>
                             </div>
                             <div className="flex items-center justify-between px-1">
                                 <div className="font-semibold">Biz model</div>
                                 <div>Kiosk + Retail</div>
                             </div>
                             <div className="flex items-center justify-between bg-back px-1">
                                 <div className="font-semibold">Break even</div>
                                 <div>6-8 months</div>
                             </div>
                             <div className="flex items-center justify-between px-1">
                                 <div className="font-semibold">ROI</div>
                                 <div>46%</div>
                             </div>
                         </div>
                     </div>
                     <div className="sm:h-96 w-full bg-sage">
                        <img src="/images/kiyos.png" alt="" className="h-full w-full object-cover" />
                     </div>
                 </div>
                 <div className="border-b pb-5 md:pb-0 sm:border-0 flex flex-col-reverse lg:grid grid-cols-2 gap-x-5 w-full md:w-4/6">
                     <div className="sm:h-96 w-full bg-sage">
                         <img src="/images/unit.png" alt="" className="h-full w-full object-cover" />
                     </div>
                     <div className="py-5 sm:p-5 bg-white flex flex-col justify-center">
                         <div className="text-3xl font-semibold">Unit Franchise</div>
                         <div className="text-sage text-lg pt-2 pb-4">Investment 18 Lakh onwards</div>
                         <div className="flex flex-col space-y-2">
                             <div className="flex items-center justify-between bg-back px-1">
                                 <div className="font-semibold">Investment</div>
                                 <div>20 - 40 Lakh</div>
                             </div>
                             <div className="flex items-center justify-between px-1">
                                 <div className="font-semibold">Area required</div>
                                 <div>700-1500 sq.ft</div>
                             </div>
                             <div className="flex items-center justify-between bg-back px-1">
                                 <div className="font-semibold">Menus</div>
                                 <div>100%</div>
                             </div>
                             <div className="flex items-center justify-between px-1">
                                 <div className="font-semibold">Biz model</div>
                                 <div>Cafe + Retail</div>
                             </div>
                             <div className="flex items-center justify-between bg-back px-1">
                                 <div className="font-semibold">Break even</div>
                                 <div>8-12 months</div>
                             </div>
                             <div className="flex items-center justify-between px-1">
                                 <div className="font-semibold">ROI</div>
                                 <div>48%</div>
                             </div>
                         </div>
                     </div>
                 </div>
                 <div className="border-b pb-5 md:pb-0 sm:border-0 grid lg:grid-cols-2 gap-x-5 w-full md:w-4/6">
                     <div className="py-5 sm:p-5 bg-white flex flex-col justify-center">
                         <div className="text-3xl font-semibold">Master Franchise</div>
                         <div className="text-sage text-lg pt-2 pb-4">Investment 25 Lakh onwards</div>
                         <div className="flex flex-col space-y-2">
                             <div className="flex items-center justify-between bg-back px-1">
                                 <div className="font-semibold">Investment</div>
                                 <div>50 Lakh & above</div>
                             </div>
                             <div className="flex items-center justify-between px-1">
                                 <div className="font-semibold">Area required</div>
                                 <div>Central kitchen + cafe/kiosk</div>
                             </div>
                             <div className="flex items-center justify-between bg-back px-1">
                                 <div className="font-semibold">Menus</div>
                                 <div>100%</div>
                             </div>
                             <div className="flex items-center justify-between px-1">
                                 <div className="font-semibold">Biz model</div>
                                 <div>Cafe + Retail + Royality</div>
                             </div>
                             <div className="flex items-center justify-between bg-back px-1">
                                 <div className="font-semibold">Break even</div>
                                 <div>12-18 months</div>
                             </div>
                             <div className="flex items-center justify-between px-1">
                                 <div className="font-semibold">ROI</div>
                                 <div>82%</div>
                             </div>
                         </div>
                     </div>
                     <div className="sm:h-96 w-full bg-sage">
                         <img src="/images/masterfranchise.jpg" alt="" className="h-full w-full object-cover" />
                     </div>
                 </div>
            </div>
        </div>
    )
}

export default BusinessModal
