import React from 'react'

function Benefits() {
    return (
        <div className="w-full font-openSans relative bg-gray-50">
            <img src="/images/black-pattern.svg" alt="" className="absolute top-0 left-0 h-full w-full object-cover" />
            <div className="w-11/12 mx-auto my-0 py-20">
                {/* top */}
                <div className="flex flex-col items-center">
                    <div className="bg-gradient-to-r from-green-400 to-sage text-white p-1 px-4 mb-2">Benefits</div>
                    <div className="flex flex-col items-center pt-3 pb-6 uppercase">
                        <div className="text-5xl lg:text-8xl font-bold">Filled</div>
                        <div className="text-5xl lg:text-8xl font-bold">With</div>
                        <div className="text-5xl lg:text-8xl font-bold">Nutrition</div>
                    </div>
                </div>
                {/* buottom */}
                <div className="grid md:grid-cols-3 grid-rows-2 gap-5 pt-8">
                    <div className="row-span-2 rounded-md">
                        <div className="bg-gradient-to-br from-green-400 to-sage rounded-md h-96 md:h-full relative">
                            <img src="/images/benefit3.png" alt="" className="absolute bottom-0 right-0 h-56 object-contain" />
                            <div className="p-5">
                                <div className="text-white text-2xl font-semibold relative z-40">Avocado Is Incredibly Nutritious</div>
                                <div className="text-white pt-3">Vitamin K: 26% of the daily value (DV)<br />
                                Folate: 20% of the DV<br />
                                Vitamin C: 17% of the DV<br />
                                Potassium: 14% of the DV<br />
                                Vitamin B5: 14% of the DV<br />
                                Vitamin B6: 13% of the DV<br />
                                Vitamin E: 10% of the DV</div>
                            </div>
                        </div>
                    </div>
                    <div className="row-span-2 rounded-md">
                        <div className="bg-gradient-to-br from-green-400 to-sage rounded-md h-96 md:h-full relative">
                            <img src="/images/benefit2.png" alt="" className="absolute bottom-0 right-0 h-40 object-contain" />
                            <div className="p-5 relative z-40">
                                <div className="text-white text-2xl font-semibold relative z-40">Avocado Is Loaded With Heart-Healthy Monounsaturated Fatty Acids</div>
                                <div className="text-white pt-3">Avocado is a high-fat food. In fact, 77% of the calories in it are from fat, making it one of the fattiest plant foods in existence. <br /><span className="hidden md:block">Avocados and avocado oil are high in monounsaturated oleic acid, a heart-healthy fatty acid that is believed to be one of the main reasons for the health benefits of olive oil.</span></div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-gradient-to-br from-green-400 to-sage rounded-md relative">
                        <img src="/images/benefit4.png" alt="" className="absolute top-0 right-0 h-full" />
                        <div className="p-5 relative z-40">
                            <div className="text-white text-2xl font-semibold relative z-40 w-4/6">They Contain More Potassium Than Bananas</div>
                            <div className="text-white w-4/6">This nutrient helps maintain electrical gradients in your body’s cells and serves various important functions.</div>
                        </div>
                    </div>
                    <div className="bg-gradient-to-br from-green-400 to-sage . rounded-md relative">
                        <img src="/images/benefit1.png" alt="" className="absolute top-0 right-0 h-full" />
                        <div className="p-5">
                            <div className="text-white text-2xl font-semibold relative z-40 w-4/6">Avocados Are Loaded With Fiber</div>
                            <div className="text-white w-4/6">Fiber is another nutrient that avocados are relatively rich in.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Benefits
