import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    id: null
}

const idSlice = createSlice({
    name: "userId",
    initialState,
    reducers: {
        getId: (state, action) => {
            state.id = action.payload
        }
    }
});

export const {
    getId
} = idSlice.actions
export const selectId = (state) => state.userId.id
export default idSlice.reducer