import React from 'react'
import { Link as Link2 } from 'react-scroll'
import { HomeIcon, DocumentIcon, ChatAltIcon } from '@heroicons/react/outline'

function DrawerNav() {
    return (
        <div className="bg-gray-50 p-5">
            <div className="flex flex-col space-y-2">
                <Link2 spy={true} smooth={true} className="p-2 px-4 flex items-center w-40" to="home"><HomeIcon className="h-6 mr-2" /><div className="font-medium flex-grow">Home</div></Link2>
                <Link2 spy={true} smooth={true} className="p-2 px-4 flex items-center w-40" to="deck"><DocumentIcon className="h-6 mr-2" /><div className="font-medium flex-grow">Pitch Deck</div></Link2>
                <Link2 spy={true} smooth={true} className="p-2 px-4 flex items-center w-40" to="touch"><ChatAltIcon className="h-6 mr-2" /><div className="font-medium flex-grow">Get in touch</div></Link2>
            </div>
        </div>
    )
}

export default DrawerNav
