import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { selectId } from '../features/idSlice'
import axios from '../axios'
import { RefreshIcon } from '@heroicons/react/outline'
import SurveyPop from './SurveyPop'

function YesForm() {
    const [buy, setbuy] = useState("")
    const [consume, setconsume] = useState("")
    const [kilo, setkilo] = useState("")
    const [buyPrice, setbuyPrice] = useState("")
    const [subscription, setsubscription] = useState("")
    const [wantprice, setwantprice] = useState("")
    const [avocadoType, setavocadoType] = useState("")
    const [loading, setloading] = useState(false)
    const [popUp, setpopUp] = useState(false)
    const setUserId = useSelector(selectId)


    // function
    const handleSubmit = async (e) => {
        e.preventDefault()
        setloading(true)
        setpopUp(false)
        if(buy === "" || consume === "" || kilo === "" || subscription === "" || wantprice === "" || buyPrice === "" || avocadoType === ""){
            alert("Enter all fields")
            setloading(false)
        }
        else{
            try{
                const data = {
                    buy: buy,
                    consume: consume,
                    buyPrice: buyPrice,
                    wantprice: wantprice,
                    kilo: kilo,
                    subscription: subscription,
                    avocadoType: avocadoType,
                    id: setUserId
                }
                const response = await axios.post("/survey/yes", data)
                if(response.status === 201){
                    setloading(false)
                    setpopUp(true)
                }
            }catch(err){
                console.log(err)
            }
        }
    }



    const handlePop = () => {
        setpopUp(false)
        window.location.reload();
    }

    return (
        <div className="py-10 mx-auto my-0 w-11/12 lg:w-7/12">
            {popUp && <SurveyPop handleClose={handlePop} />}
            <form onSubmit={handleSubmit} className="flex flex-col space-y-8">
                <div>
                    <label htmlFor="" className="text-lg">From where do you buy Avocado?</label>
                    <input type="text" className="border-2 outline-none w-full p-2 px-4 mt-1 rounded-md border-gray-300" placeholder="Big basket | Market | Amazon ..." value={buy} onChange={(e) => setbuy(e.target.value)}  />
                </div>
                <div>
                    <label htmlFor="" className="text-lg">How often do you like to consume avocado?</label>
                    <div className="grid grid-cols-2 gap-3 pt-2">
                        <div className={`border-2 outline-none w-full p-2 px-4  rounded-md border-gray-300 ${consume === "once" ? "border-sage text-sage bg-green-50": ""}`} onClick={() => setconsume("once")}>Once a week </div>
                        <div className={`border-2 outline-none w-full p-2 px-4  rounded-md border-gray-300 ${consume === "twice" ? "border-sage text-sage bg-green-50": ""}`} onClick={() => setconsume("twice")}>Twice a week</div>
                        <div className={`border-2 outline-none w-full p-2 px-4  rounded-md border-gray-300 ${consume === "thrice" ? "border-sage text-sage bg-green-50": ""}`} onClick={() => setconsume("thrice")}>Thrice a week</div>
                        <div className={`border-2 outline-none w-full p-2 px-4  rounded-md border-gray-300 ${consume === "daily" ? "border-sage text-sage bg-green-50": ""}`} onClick={() => setconsume("daily")}>As much as i can</div>
                    </div>
                </div>
                <div>
                    <label htmlFor="" className="text-lg">At what price do you buy 1 Avocado?</label>
                    <input type="text" className="border-2 outline-none w-full p-2 px-4 mt-1 rounded-md border-gray-300" placeholder=""  value={buyPrice} onChange={(e) => setbuyPrice(e.target.value)} />
                </div>
                <div>
                    <label htmlFor="" className="text-lg">At what price you’d like to buy 1 Avocado?</label>
                    <div className="flex items-center space-x-3 pt-2">
                        <div className="w-full">
                            <div className={`border-2 outline-none w-full p-2 px-4  rounded-md border-gray-300 ${wantprice === "59" ? "border-sage text-sage bg-green-50": ""}`} onClick={() => setwantprice("59")}>59 (Indian) and 249 (Imported)</div>
                            <div className="text-sm text-gray-500">Avoke price</div>
                        </div>
                        <div className="w-full">
                            <div className={`border-2 outline-none w-full p-2 px-4  rounded-md border-gray-300 ${wantprice === "69" ? "border-sage text-sage bg-green-50": ""}`} onClick={() => setwantprice("69")}>69 (Indian) 270 (Imported)</div>
                            <div className="text-sm text-gray-500">Current market price</div>
                        </div>
                    </div>
                </div>
                <div>
                    <label htmlFor="" className="text-lg">How many Avocados do you buy monthly?</label>
                    <div className="flex items-center space-x-3 pt-2">
                        <div className={`border-2 outline-none w-full p-2 px-4  rounded-md border-gray-300 ${kilo === "1" ? "border-sage text-sage bg-green-50": ""}`} onClick={() => setkilo("1")}>Less than 1kg</div>
                        <div className={`border-2 outline-none w-full p-2 px-4  rounded-md border-gray-300 ${kilo === "1-3" ? "border-sage text-sage bg-green-50": ""}`} onClick={() => setkilo("1-3")}>1-3kg</div>
                        <div className={`border-2 outline-none w-full p-2 px-4  rounded-md border-gray-300 ${kilo === "3" ? "border-sage text-sage bg-green-50": ""}`} onClick={() => setkilo("3")}>More than 3kg</div>
                    </div>
                </div>
                <div>
                    <label htmlFor="" className="text-lg">Would you like to get a subscription?</label>
                    <div className="flex items-center space-x-5 pt-2">
                        <div className={`border-2 outline-none w-full p-2 px-4  rounded-md border-gray-300 ${subscription === "yes" ? "border-sage text-sage bg-green-50": ""}`} onClick={() => setsubscription("yes")}>Yes</div>
                        <div className={`border-2 outline-none w-full p-2 px-4  rounded-md border-gray-300 ${subscription === "no" ? "border-sage text-sage bg-green-50": ""}`} onClick={() => setsubscription("no")}>No</div>
                    </div>
                </div>
                <div>
                    <label htmlFor="" className="text-lg">Which avocado do you like to consume?</label>
                    <div className="flex items-center space-x-5 pt-2">
                        <div className={`border-2 outline-none w-full p-2 px-4  rounded-md border-gray-300 ${avocadoType === "indian" ? "border-sage text-sage bg-green-50": ""}`} onClick={() => setavocadoType("indian")}>Indian Avocado</div>
                        <div className={`border-2 outline-none w-full p-2 px-4  rounded-md border-gray-300 ${avocadoType === "hass" ? "border-sage text-sage bg-green-50": ""}`} onClick={() => setavocadoType("hass")}>Hass Avocados</div>
                    </div>
                </div>
                <div className="flex justify-end">
                    <button type="submit" className="bg-sage text-white p-3 px-6 rounded-md flex items-center">Submit {loading && <RefreshIcon className="h-5 animate-spin duration-200 ml-2" />}</button>
                </div>
            </form>
        </div>
    )
}

export default YesForm
