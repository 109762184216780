import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { RefreshIcon, CheckIcon } from '@heroicons/react/outline'
import axios from '../axios'
import { BiMailSend } from 'react-icons/bi'

function GetinTouch() {
    const { register, handleSubmit, formState: { errors }, reset } = useForm()
    const [loading, setloading] = useState(false)
    const [success, setsuccess] = useState(false)

    const onSubmit = async (data) => {
        setloading(true)
        try{
            const response = await axios.post("/franchise", data)
            if(response.status === 201){
                setloading(false)
                setsuccess(true)
                reset()
            }
        }catch(err){
            console.log(err)
        }
    }

    return (
        <div className="w-full font-openSans bg-white" id="touch">
            <div className="w-11/12 md:w-9/12 mx-auto my-0 py-20 max-w-screen-2xl">
                <div className="flex flex-col items-center">
                    <div className="text-3xl font-semibold">Get in touch</div>
                    <div className="text-base md:text-lg md:w-4/6 text-center pt-3 pb-10 text-gray-600">If your are interested in our concept, we'd love to hear from you. Send us your details using this form and our franchising team will reach out to you.</div>
                </div>
                {/* form */}
                <div className="bg-white border rounded-md sm:p-5 md:grid grid-cols-3 gap-5">
                    <div className="bg-sage p-5 py-10 rounded-md relative">
                        <div className="text-xl md:text-2xl font-medium text-white">Contact Information</div>
                        <div className="text-white">Talk directly with us. we'd love to hear from you.</div>
                        <div className="pt-10">
                            <div className="flex items-center space-x-3">
                                <BiMailSend size={23} color="#ffffff" />
                                <div className="text-white">hello@avoke.in</div>
                            </div>
                        </div>
                        <img src="/images/bg-three.png" alt="" className="absolute -right-4 bottom-0 h-32 sm:h-52 object-contain" />
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)} className="col-span-2 px-5 flex flex-col space-y-3 md:grid md:space-y-0 grid-cols-2 gap-10  md:px-20 py-10">
                        <div className="flex flex-col relative">
                            <label htmlFor="" className="text-sm text-gray-600">Name</label>
                            <input type="text" className="outline-none border-b p-2" placeholder="Enter your name!" {...register('name', {
                                required: {
                                    value: true,
                                    message: 'Enter your name'
                                }
                            })} />
                            {errors.name && <p className="text-sm absolute -bottom-5 text-red">{errors.name.message}</p>}
                        </div>
                        <div className="flex flex-col relative">
                            <label htmlFor="" className="text-sm text-gray-600">Email</label>
                            <input type="email" className=" outline-none border-b p-2" placeholder="Enter your email!" {...register("email", {
                                required: {
                                    value: true,
                                    message: "Please enter your email"
                                }
                            })} />
                            {errors.email && <p className="text-sm absolute -bottom-5 text-red">{errors.email.message}</p>}
                        </div>
                        <div className="flex flex-col relative">
                            <label htmlFor="" className="text-sm text-gray-600">Phone</label>
                            <input type="text" className=" outline-none border-b p-2" placeholder="Enter your phone number" {...register("phone", {
                                required: {
                                    value: true,
                                    message: "Enter your phone number"
                                }
                            })} />
                            {errors.phone && <p className="text-sm absolute -bottom-5 text-red">{errors.phone.message}</p>}
                        </div>
                        <div className="flex flex-col relative">
                            <label htmlFor="" className="text-sm text-gray-600">Location</label>
                            <input type="text" className=" outline-none border-b p-2" placeholder="Where are you interested to start." {...register("location", {
                                required: {
                                    value: true,
                                    message: "Enter location."
                                }
                            })} />
                            {errors.location && <p className="text-sm absolute -bottom-5 text-red">{errors.location.message}</p>}
                        </div>
                        <div className="flex flex-col col-span-2 relative">
                            <label htmlFor="" className="text-sm text-gray-600">Intrested In</label>
                            <select name="" id="" className=" outline-none border-b p-2" {...register("choice", {
                                required: {
                                    value: true,
                                    message: "Enter your choise"
                                }
                            })}>
                                <option value="master franchise">Master Franchise</option>
                                <option value="unit franchise">Unit Franchise</option>
                                <option value="kiosk">Kiosk</option>
                            </select>
                            {errors.choise && <p className="text-sm absolute -bottom-5 text-red">{errors.choise.message}</p>}
                        </div>
                        <div className="flex flex-col col-span-2 relative">
                            <label htmlFor="" className="text-sm text-gray-600">Message</label>
                            <textarea type="text" className=" outline-none border-b pt-2 h-32 sm:h-auto" placeholder='Tell us about your interest in avoke cafe.' {...register("message", {
                                required: {
                                    value: true,
                                    message: "Fill this field."
                                }
                            })} />
                            {errors.message && <p className="text-sm absolute -bottom-5 text-red">{errors.message.message}</p>}
                        </div>
                        <div className="col-span-2">
                            <button type="submit" className="bg-sage disabled:opacity-50 rounded-md  text-white p-3 px-6 flex items-center">Get Deck now {loading && <RefreshIcon className="h-6 ml-2 animate-spin delay-200" />}</button>
                        </div>
                    </form>
                </div>
            </div>
            {success === true && <Success success={success} setsuccess={setsuccess} />}
        </div>
    )
}

export default GetinTouch


const Success = ({success, setsuccess}) => {
    return(
        <div className="w-full h-screen fixed top-0 z-50 bottom-0 flex items-center justify-center bg-black bg-opacity-25">
            <div className="flex flex-col items-center bg-white w-max p-10 shadow-xl">
                <CheckIcon className="h-10 bg-sage text-white rounded-full p-2 mb-2" />
                <div className="text-2xl font-semibold">Thank you for your interest.</div>
                <div></div>
                <a onClick={() => setsuccess(false)} href="https://firebasestorage.googleapis.com/v0/b/avoke-in.appspot.com/o/Avoke%20Franchise%20Deck.pdf?alt=media&token=7d647281-21d4-467e-9427-1869f9aed149" className="p-3 px-6 bg-sage text-white mt-8">Click to Download</a>
            </div>
        </div>
    )
}
