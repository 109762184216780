import React from 'react'
import GetinTouch from '../components/GetinTouch'
import Testimonials from '../components/Testimonials'
import { Link } from 'react-scroll'
import Chef from '../components/Chef'
import FranchiseHeader from '../components/FranchiseHeader'
import Support from '../components/Support'
import BusinessModal from '../components/BusinessModal'
import FranchiseDeck from '../components/FranchiseDeck'
import Footer from '../components/Footer'

function Franchise() {
    return (
        <div className="w-full font-openSans text-gray-800 bg-back">
            <FranchiseHeader design={"fixed top-0 left-0 w-full z-50"} />
            <div id="home" className="w-11/12 mx-auto my-0 flex flex-col lg:flex-row min-h-screen h-screen max-w-screen-2xl">
                {/* left */}
                <div className="flex-1 flex flex-col justify-center pt-20 sm:pt-0">
                    <div>
                        <div className="text-4xl lg:text-5xl font-bold">Let's taste the goodness of <br /><span className="text-5xl md:text-7xl text-sage">Hass Av<span className="">o</span>cados</span></div>
                        <div className="text-lg md:text-xl lg:text-3xl pt-3 sm:pt-5 text-gray-700">Avoke cafe, India's first avocado cafe</div>
                    </div>
                    {/* buttons */}
                    <div className="pt-10 flex items-center space-x-6">
                        <Link to="touch" spy={true} smooth={true} className="bg-sage cursor-pointer text-white rounded-md p-3 px-6">Own a Franchise</Link>
                        <Link to="deck" spy={true} smooth={true} className="p-3 cursor-pointer px-6">Know More</Link>
                    </div>
                </div>
                {/* right */}
                <div className="flex-1"> 
                    <img src="images/bg-one.png" alt="" className="object-cover h-full w-full hidden lg:block" />
                    <img src="images/bg-mobile.png" alt="" className="object-contain lg:hidden" />
                </div>
            </div>
            <FranchiseDeck />
            <Chef />
            <BusinessModal />
            <Support />
            <Testimonials />
            <GetinTouch />
            <Footer />
        </div>
    )
}

export default Franchise
