import React, { useState } from 'react'
import { Drawer } from '@material-ui/core'
import { MenuAlt3Icon } from '@heroicons/react/outline'
import { Link } from 'react-scroll'
import FranchisePop from './FranchisePop'
import DrawerNav from './DrawerNav'

function FranchiseHeader({design}) {
    const [drawerState, setdrawerState] = useState(false)
    const [contactPop, setcontactPop] = useState(false)
    return (
        <header className={` w-full font-openSans backdrop-filter backdrop-blur-lg ${design}`}>
            {contactPop && <FranchisePop handleClose={() => setcontactPop(false)} />}
            <div className="flex items-center justify-between py-5 sm:py-2 w-11/12 mx-auto my-0">
                <img src="/images/logo.png" alt="" className="h-6 sm:h-12 sm:py-2" />
                <div className="hidden sm:flex items-center space-x-10">
                    <Link to="home" spy={true} smooth={true} className="cursor-pointer text-base">Home</Link>
                    <Link to="deck" spy={true} smooth={true} className="cursor-pointer text-base">Pitch Deck</Link>
                    <Link to="touch" spy={true} smooth={true} className="cursor-pointer text-base">Get in touch</Link>
                    <div className="cursor-pointer text-base bg-sage text-white p-2 px-6 rounded-md" onClick={() => setcontactPop(true)}>Contact</div>
                </div>
                <div className="sm:hidden">
                    <MenuAlt3Icon className="h-6" onClick={() => setdrawerState(true)} />
                </div>
            </div>
            <Drawer anchor="right" open={drawerState} onClose={() => setdrawerState(false)}>
                <DrawerNav />
            </Drawer>
        </header>
    )
}

export default FranchiseHeader
