import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { selectId } from '../features/idSlice'
import axios from '../axios'
import { RefreshIcon } from '@heroicons/react/outline'
import SurveyPop from './SurveyPop'

function NoForm() {
    const [tasted, settasted] = useState("")
    const [diet, setdiet] = useState("")
    const [tryHass, settryHass] = useState("")
    const setuserId = useSelector(selectId)
    const [loading, setloading] = useState(false)
    const [popUp, setpopUp] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault()
        setloading(true)
        setpopUp(false)
        if(tasted === "" || diet === "" || tryHass === ""){
            alert("Fill all fields")
            setloading(false)
        }else{
            try{
                const data = {
                    tasted: tasted,
                    diet: diet,
                    tryHass: tryHass,
                    id: setuserId
                }

                const response = await axios.post("/survey/no", data)
                if(response.status === 201){
                    console.log(response.data)
                    setloading(false)
                    setpopUp(true)
                }
    
    
            }catch(err){
                console.log(err)
            }
        }
    }

    const handlePop = () => {
        setpopUp(false)
        window.location.reload();
    }
    return (
        <div className="pb-10 w-11/12 lg:w-6/12 mx-auto my-0">
            {popUp && <SurveyPop handleClose={handlePop} />}
            <form onSubmit={handleSubmit} className="flex flex-col space-y-8">
                <div>
                    <label htmlFor="" className="text-lg">Have you ever tasted it?</label>
                    <div className="flex items-center space-x-3 pt-2">
                        <div className={`border-2 cursor-pointer outline-none w-full p-2 px-4  rounded-md border-gray-300 ${tasted === "yes" ? "border-sage text-sage bg-green-50": ""}`} onClick={() => settasted("yes")}>Yes</div>
                        <div className={`border-2 cursor-pointer outline-none w-full p-2 px-4  rounded-md border-gray-300 ${tasted === "no" ? "border-sage text-sage bg-green-50": ""}`} onClick={() => settasted("no")}>No</div>
                    </div>
                </div>
                <div>
                    <label htmlFor="" className="text-lg">Why don’t you incorporate it in your diet?</label>
                    <div className="flex items-center space-x-3 pt-2">
                        <div className={`border-2 cursor-pointer outline-none w-full p-2 px-4  rounded-md border-gray-300 ${diet === "expensive" ? "border-sage text-sage bg-green-50": ""}`} onClick={() => setdiet("expensive")}>Too expensive</div>
                        <div className={`border-2 cursor-pointer outline-none w-full p-2 px-4  rounded-md border-gray-300 ${diet === "availability" ? "border-sage text-sage bg-green-50": ""}`} onClick={() => setdiet("availability")}>Non-availability </div>
                    </div>
                </div>
                <div>
                    <label htmlFor="" className="text-lg">Would you like to try Avoke Hass Avocado and then decide for yourself?</label>
                    <div className="flex items-center space-x-3 pt-2">
                        <div className={`border-2 cursor-pointer outline-none w-full p-2 px-4  rounded-md border-gray-300 ${tryHass === "yes" ? "border-sage text-sage bg-green-50": ""}`} onClick={() => settryHass("yes")}>Yes, definitely </div>
                        <div className={`border-2 cursor-pointer outline-none w-full p-2 px-4  rounded-md border-gray-300 ${tryHass === "no" ? "border-sage text-sage bg-green-50": ""}`} onClick={() => settryHass("no")}>No, not interested</div>
                    </div>
                </div>
                <div className="flex justify-end">
                    <button type="submit" className="bg-sage text-white p-3 px-6 rounded-md flex items-center">Submit {loading && <RefreshIcon className="h-5 animate-spin duration-200 ml-2" />}</button>
                </div>
            </form>
        </div>
    )
}

export default NoForm
