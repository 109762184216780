import React from 'react'
import { Link } from 'react-router-dom'
import Testimonials from '../components/Testimonials'
import About from '../components/About'
import RealEstate from '../components/RealEstate'
import Footer from '../components/Footer'
import Header from '../components/Header'
import Benefits from '../components/Benefits'
import WhyAvoke from '../components/WhyAvoke'
import Award from '../components/Award'

function Home() {
    return (
        <div className="w-full font-openSans text-gray-800">
            <Header design={"fixed top-0 left-0 w-full z-50"} />
            <div id="home" className="w-11/12 py-20 mx-auto my-0 flex flex-col lg:flex-row h-full  sm:min-h-screen sm:h-screen max-w-screen-2xl">
                {/* left */}
                <div className="flex-1 flex flex-col justify-center">
                    <div className="pt-10 md:pt-0">
                        <div className="text-4xl lg:text-5xl font-bold">World's best <br /><span className="text-5xl md:text-7xl text-sage">Hass Av<span className="">o</span>cados</span></div>
                        <div className="text-lg md:text-xl pt-3 sm:pt-5 text-gray-700">Get the goodness of HASS Avocado Full of heathy fats From our Bay Of Plenty orchards, direct to your door. Our fresh new season Hass is here.</div>
                    </div>
                    {/* buttons */}
                    <div className="pt-10 flex items-center space-x-6">
                        <Link to="/survey" className="bg-sage cursor-pointer text-white rounded-md p-3 px-6">Survey</Link>
                        <Link to="/franchise" className="p-3 cursor-pointer px-6">Franchise</Link>
                    </div>
                </div>
                {/* right */}
                <div className="flex-1"> 
                    <img src="images/bg-three.png" alt="" className="object-contain h-full w-full" />
                </div>
            </div>
            <Award />
            <Benefits />
            <WhyAvoke />
            <About />
            <Testimonials />
            {/* <RealEstate /> */}
            <Footer />   
        </div>
    )
}

export default Home
