import React from 'react'
import { Link } from 'react-scroll'

function FranchiseDeck() {
    return (
        <div className="w-full font-openSans bg-white" id="deck">
            <div className="w-11/12 py-20 mx-auto my-0 grid lg:grid-cols-2 gap-10 max-w-screen-2xl">
                {/* left */}
                <div className="">
                    <img src="https://cdn.dribbble.com/users/1585644/screenshots/9274383/media/265178e41af5bb9f5441cbe3b02ea9e3.png?compress=1&resize=1600x1200" alt="" className="object-contain w-full sm:h-96" />
                </div>
                {/* right */}
                <div className="flex flex-col justify-center">
                    <div className="text-lg text-sage">Here everything is made of Hass Avocados.</div>
                    <div className="text-3xl md:text-5xl font-semibold pt-2">It's not just food, it's an experience, it's Health.</div>
                    <div className="text-base pt-4">Avocado Is Loaded With Heart-Healthy Monounsaturated Fatty Acids. Avocado is a high-fat food. In fact, 77% of the calories in it are from fat, making it one of the fattiest plant foods in existence. But they don't just contain any fat.</div>
                    <div className="pt-8">
                        <Link to="touch" spy={true} smooth={true} className="bg-sage text-white rounded-md p-3 px-6">Download Deck</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FranchiseDeck
