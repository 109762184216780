import React from 'react'
import { GrBlockQuote } from 'react-icons/gr'

function Chef() {
    return (
        <div className="w-full font-openSans bg-back">
            <div className="w-11/12 mx-auto my-0 flex flex-col space-y-5 md:space-y-0 md:flex-row max-w-screen-2xl">
                {/* left */}
                <div className="flex-1 py-20 relative">
                    <div className="relative">
                        <GrBlockQuote size={60} className="absolute -top-5 -left-5" color="#BDBF76" />
                        <div className="text-3xl font-semibold relative z-30">If you're a happy person around food, you can be a professional chef. It's fueled by passion.</div>
                    </div>
                    <div className="md:text-lg pt-4">An engineer from Bangalore who was once employed at a technology giant with promising career prospects. Technology brings about a revolution in whichever field one applies it to. What it improves in terms of efficiency and performance, it removes in emotions and feelings. The paradigm shift occurred when he realized that his sedentary life would prevent him from living his philosophy of 'being free and not bound by populist life goals'.</div>
                    <div className="pt-10 flex items-center">
                        <div className="h-16 w-16 rounded-full bg-sage"></div>
                        <div className="pl-2">
                            <div className="text-lg font-semibold">Chef. Shukla</div>
                            <div>Person behind the menu.</div>
                        </div>
                    </div>
                </div>
                <div className="flex-1 flex justify-center items-center pb-10 sm:py-0 relative">
                    <div className="h-96 w-full flex justify-center">
                        <img src="/images/chef-2d.svg" alt="" className="h-full w-5/6 object-contain" />
                    </div>
                    <img src="/images/chef-bg.png" alt="" className="absolute -bottom-16 right-0 md:-right-10 h-32 md:h-48" />
                    
                </div>
            </div>
        </div>
    )
}

export default Chef
