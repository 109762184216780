import React from 'react'

function WhyAvoke() {
    return (
        <div className="w-full font-openSans">
            <div className="w-11/12 mx-auto py-20 my-0 flex flex-col md:flex-row space-y-8 md:space-y-0">
                {/* left */}
                <div className="flex-1 sm:flex justify-center">
                    <img src="/images/why.svg" alt="" className="w-full object-contain sm:object-cover rounded-md" />
                </div>
                {/* right */}
                <div className="flex-1 flex flex-col justify-center">
                    <div className="pb-10">
                        <div className="text-sage">Why choose us.</div>
                        <div className="text-4xl font-semibold">We are authentic.</div>
                    </div>
                    <div className="bg-white border p-5 mb-10 rounded-md">
                        <div className="text-xl font-semibold pb-1">Quality Service</div>
                        <div>Grading is done so as to supply the best quality to you. We want you to get the best that you indeed deserve.</div>
                    </div>
                    <div className="bg-white border p-5 mb-10 rounded-md">
                        <div className="text-xl font-semibold pb-1">Affordable Prices</div>
                        <div>The best at the most reasonable prices is possible because of the volume and we are very glad to pass on the benefits to you.</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WhyAvoke
