import React, { useState } from 'react'
import NoForm from '../components/NoForm'
import YesForm from '../components/YesForm'
import { useForm } from 'react-hook-form'
import axios from '../axios'
import { useDispatch } from 'react-redux'
import { getId } from '../features/idSlice'
import { RefreshIcon } from '@heroicons/react/outline'

function Survey() {
    const [page, setpage] = useState(1)
    const [haveAvo, sethaveAvo] = useState("")
    const dispatch = useDispatch()
    const [loading, setloading] = useState(false)

    const { register, handleSubmit, formState: { errors } } = useForm()


    // had avo or not
    const hadAvo = () => {
        sethaveAvo("yes")
        setpage(3)
    }

    const noAvo = () => {
        sethaveAvo("no")
        setpage(3)
    }

    // form submit
    const onSubmit =  async (data) => {
        setloading(true)
        if(data.name === '' || data.email === '' || data.phone === ''){
            alert("Fill all fields")
            setpage(1)
            setloading(false)
        }else{
            try{
                const response = await axios.post("/survey", data)
                if(response.status === 201){
                    setpage(2)
                    setloading(false)
                    dispatch(getId(response.data.data._id))
                }
            }catch(e){
                console.log(e)
            }
        }
    }



    return (
        <div className="w-full font-openSans bg-back">
            <div className="md:w-8/12 mx-auto my-0 shadow-lg bg-white min-h-screen flex flex-col">
                {/* top */}
                <div>
                    <div className="flex flex-col items-center  px-10 pt-10 pb-2 ">
                        <img src="/images/logo.png" alt="" className="h-8" />
                        <div>The first Indian avocado brand.</div>
                    </div>
                    <div className="bg-back px-10 py-1 flex justify-end">
                        <div className="text-gray-400">{new Date().toLocaleDateString()}</div>
                    </div>
                </div>
                {/* survey */}
                <div className="flex-grow flex justify-center items-center">
                        <form onSubmit={handleSubmit(onSubmit)} className="pb-10 flex flex-col justify-between w-11/12 lg:w-6/12 relative">
                            {page === 1 && <div>
                                <div className="pb-14">
                                    <div className="text-center text-sm text-gray-400">Personal Info</div>
                                    <div className="text-2xl font-semibold text-center">Enter your personal details.</div>
                                </div>
                                <div className="flex flex-col space-y-8">
                                    <input type="text" className="border-2 w-full p-2 px-4 outline-none rounded-md border-gray-300 focus:outline-sage" placeholder="Enter your name" {...register("name", {
                                        required: {
                                            value: true,
                                            message: "Enter your email address"
                                        }
                                    })} />
                                    <input className="border-2 outline-none w-full p-2 px-4  rounded-md border-gray-300" placeholder="Enter email address" type="email" {...register("email", {
                                        required: {
                                            value: true,
                                            message: "Enter your email address"
                                        }
                                    })} />
                                    <div className="flex items-center rounded-md border-gray-300 border-2 ">
                                        <label className="p-2 text-gray-500 border-r-2">+91</label>
                                        <input type="text" className="outline-none w-full p-2 px-4 " placeholder="" {...register("phone", {
                                            required: {
                                                value: true,
                                                message: "Enter your mobile number"
                                            },
                                            pattern: {
                                                value: /^[0-9]{10}$/,
                                                message: "Enter valid number"
                                            }
                                        })} />
                                    </div>
                                    {errors.phone && <p className="text-sm text-red">{errors.phone.message}</p>}
                                </div>
                            </div>} 
                            {page === 2 && <div className="w-11/12 md:w-full mx-auto my-0">
                                <div className="pb-14">
                                    <div className="text-center text-sm text-gray-400">Taste reviews</div>
                                    <div className="text-2xl font-semibold text-center">Do you include avocado in your diet?</div>
                                </div>
                                <div className="flex items-center space-x-3 md:w-5/6 mx-auto my-0">
                                    <div className={`border-2 cursor-pointer outline-none w-full p-2 px-4  rounded-md border-gray-300 ${haveAvo === "yes" ? "border-sage text-sage bg-green-50": ""}`} onClick={hadAvo}>Yes</div>
                                    <div className={`border-2 cursor-pointer outline-none w-full p-2 px-4  rounded-md border-gray-300 ${haveAvo === "no" ? "border-sage text-sage bg-green-50": ""}`} onClick={noAvo}>No</div>
                                </div>
                            </div>}

                        {/* buttons */}
                        <div className="bg-blue-100 absolute right-5 md:right-0 -bottom-11">
                            {page !== 3 && page !== 2 && <button type="submit" className="bg-sage text-white p-3 px-6 rounded-md flex items-center">Next {loading && <RefreshIcon className="h-5 animate-spin duration-200 ml-2" />}</button>}
                        </div>
                    </form>
                </div>
                {page === 3 && <div>
                        {haveAvo === "yes" ? <YesForm /> : <NoForm />}
                    </div>}
            </div>
        </div>
    )
}

export default Survey


