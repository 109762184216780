import React from 'react'
import { Link } from 'react-scroll'

function Support() {
    const support = [
        { 
            support: "Operational Management"
        },
        {
            support: "Supply Chain Management"
        },
        {
            support: "New Product Development"
        },
        {
            support: "Training and Development"
        },
        {
            support: "Marketing Support"
        },
        {
            support: "Location Selection"
        }
    ]
    return (
        <div className="w-full font-openSans bg-back">
            <div className="w-11/12 py-20 mx-auto my-0 flex flex-col space-y-5 md:space-y-0 md:flex-row-reverse max-w-screen-2xl">
                {/* left */}
                <div className="flex-1 pb-10 md:pb-0 md:px-10">
                    <div className="text-3xl font-semibold">Support we <span className="underline text-sage">provide.</span></div>
                    <div className="pt-2">As we continue to expand, we’re looking for like-minded franchisees to join us. Our goal is to create lasting partnerships by combining the power of our brand with the knowledge, experience, and entrepreneurial mindset of franchisees who share our DNA.</div>
                    <div className="pt-8">
                        <Link to="touch" smooth={true} spy={true} className="bg-sage cursor-pointer hover:scale-105 text-sm rounded-md text-white p-3 px-6">Get in Now</Link>
                    </div>
                </div>
                {/* right */}
                <div className="flex-1 grid md:grid-cols-2 gap-5 pr-0 md:pr-5">
                {support.map((item, index) => (
                    <div index={index} className="bg-white p-5 border">
                        <div className="flex justify-center">
                            <div></div>
                            <div>{item.support}</div>
                        </div>
                    </div>
                ))}
                </div>
            </div>
        </div>
    )
}

export default Support
